import React, { useState } from "react";
import { Link } from "react-router-dom";
import PetitionSignatureModal from "./PetitionSignatureModal";
import auth from "../../utils/auth";
import { useAppSelector } from "../../store/hooks";

const PetitionDetailsSignup = () => {
  const { userSignature } = useAppSelector((state) => state.petition);
  const [open, setOpen] = useState(false);
  const closeModel = () => {
    setOpen(false);
  };
  let token = auth.getToken();
  return (
    <div className="lg:col-span-4 col-span-12">
      <div className="bg-white shadow-theme_shadow p-6 rounded-md">
        <h4 className="text-black text-opacity-60 xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold">
          Sign This Petition
        </h4>
        {token ? (
          userSignature && userSignature?.own_signature > 0 ? (
            <div className="border-t border-b border-green-500 my-6 py-4">
              <button className="text-green-500 text-base font-normal">
                You already signed this petition
              </button>
            </div>
          ) : (
            <div
              onClick={() => setOpen(true)}
              className="border-t border-b border-red-500 my-6 py-4"
            >
              <button className="text-red-500 text-base font-normal">
                If you agree, please sign this petition
              </button>
            </div>
          )
        ) : (
          <>
            <div className="border-t border-b border-red-500 my-6 py-4">
              <Link to="/" className="text-red-500 text-base font-normal">
                To Signup Petition you need to be member of Amarneta.com
              </Link>
            </div>
            <p className="text-sm text-black text-opacity-40 font-normal">
              Please
              <Link to="/login" className="mx-1 text-green-500">
                Login
              </Link>{" "}
              or{" "}
              <Link to="/registration" className="mx-1 text-green-500">
                Signup
              </Link>{" "}
              this petition.
            </p>
          </>
        )}

        {/* <div className="flex items-center gap-2 pt-3">
          <span className="text-black text-sm font-normal">Social Login</span>
          <div className="pt-2 pb-4 md:py-9 w-full md:w-auto flex items-center justify-center">
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.55016 21.75C16.6045 21.75 21.5583 14.2468 21.5583 7.74192C21.5583 7.53098 21.5536 7.31536 21.5442 7.10442C22.5079 6.40752 23.3395 5.54432 24 4.55536C23.1025 4.95466 22.1496 5.21544 21.1739 5.3288C22.2013 4.71297 22.9705 3.74553 23.3391 2.60583C22.3726 3.17862 21.3156 3.58267 20.2134 3.80067C19.4708 3.01162 18.489 2.48918 17.4197 2.31411C16.3504 2.13905 15.2532 2.32111 14.2977 2.83216C13.3423 3.3432 12.5818 4.15477 12.1338 5.14137C11.6859 6.12798 11.5754 7.23468 11.8195 8.29036C9.86249 8.19215 7.94794 7.68377 6.19998 6.79816C4.45203 5.91255 2.90969 4.6695 1.67297 3.14958C1.0444 4.2333 0.852057 5.51571 1.13503 6.73615C1.418 7.9566 2.15506 9.02351 3.19641 9.72005C2.41463 9.69523 1.64998 9.48474 0.965625 9.10598V9.16692C0.964925 10.3042 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1932 3.43198 14.2221 2.69484 14.0794C3.00945 15.0575 3.62157 15.913 4.44577 16.5264C5.26997 17.1398 6.26512 17.4807 7.29234 17.5013C5.54842 18.8712 3.39417 19.6142 1.17656 19.6107C0.783287 19.6101 0.390399 19.586 0 19.5385C2.25286 20.9838 4.87353 21.7514 7.55016 21.75Z"></path>
              </svg>
            </Link>
            <Link to="/">
              <svg
                className="w-5 h-5 fill-gray-400 hover:fill-primary-500 ml-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"></path>
              </svg>
            </Link>
          </div>
        </div> */}
      </div>
      <PetitionSignatureModal closeModel={closeModel} open={open} />
    </div>
  );
};

export default PetitionDetailsSignup;
