import React from "react";
import ModalContainer from "../common/ModalContainer";
import { useForm } from "react-hook-form";
import { petitionSignature } from "../../store/API/PetitionApis";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

type PropsTypes = {
  open: boolean;
  closeModel: () => void;
};

const PetitionSignatureModal: React.FC<PropsTypes> = ({ closeModel, open }) => {
    const { error } = useAppSelector(state => state.petition)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit((data) => {
    try {
      dispatch(
        petitionSignature({
          petition: id,
          opinion: data?.opinion,
        })
        );
        closeModel()
      toast.success("Petition Successfully Submitted");
    } catch (error) {
      console.log(error);
    }
  });
  return (
    <ModalContainer open={open} closeModal={closeModel}>
      <div className="pr_overlay fixed left-0 top-0 h-full w-full overflow-x-hidden overflow-y-auto bg-[rgba(0,0,0,0.5)] z-20">
        <div className="modal_dialog relative w-auto transform-none xl:max-w-[600px] lg:max-w-[500px] md:max-w-[450px] sm:max-w-[400px] xs:max-w-[400px] xxs:max-w-[340px] sm:min-h-[calc(100%_-_3.5rem)] min-h-[calc(100%_-_1rem)] flex items-center my-8 mx-auto">
          <div className="bg-white relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg p-6">
            <span
              className="inline-flex justify-center items-center h-8 w-8 rounded-full bg-black bg-opacity-80 absolute -top-1 -right-1 z-50 cursor-pointer"
              id="em_close"
              onClick={closeModel}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
            <div className="e_modal_body">
              <h1 className="font-bold mb-3 text-center">Signature</h1>
              <form onSubmit={onSubmit}>
                <div className="flex flex-wrap flex-row">
                  <div className="mb-4 pr-0 lg:pr-3 relative w-full bg-white group rounded-md">
                    <input
                      {...register("opinion", { required: true })}
                      id="opinion"
                      type="text"
                      name="opinion"
                      className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="opinion"
                      className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
                    >
                      Opinion <span className="text-red-500">&nbsp; *</span>
                    </label>
                    {errors?.opinion && (
                      <p className="text-red-500 text-start">
                        Opinion is required.
                      </p>
                    )}
                    {error?.message && (
                      <p className="text-red-500 text-start">
                        {error?.message}.
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PetitionSignatureModal;
