import { createSlice } from "@reduxjs/toolkit";
import { PetitionResult, petitionType, SignatureResult } from "../models";
import { getPetitionDetailsAction, getUserSignatureAction, myPetitionListAction, petitionSignature, publicPetitionListAction } from "./API/PetitionApis";

const initialState: {
  loading: boolean;
  ownerPetitionList: PetitionResult | null;
  publicPetitionList: PetitionResult | null;
  petitionDetails: petitionType;
  userSignature: SignatureResult | null;
  error: any;
} = {
  loading: false,
  ownerPetitionList: null,
  publicPetitionList: null,
  petitionDetails: {},
  userSignature: null,
  error: null,
};

const petitionSlice = createSlice({
  name: "petition",
  initialState: initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(myPetitionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.ownerPetitionList = action.payload;
    });
    builder.addCase(getPetitionDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.petitionDetails = action.payload;
    });
    builder.addCase(publicPetitionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.publicPetitionList = action.payload;
    });
    builder.addCase(getUserSignatureAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userSignature = action.payload;
    });
    builder.addCase(petitionSignature.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(petitionSignature.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(petitionSignature.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
  reducers: {},
});

export const petitionActions = petitionSlice.actions;
export default petitionSlice.reducer;
