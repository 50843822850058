import React, { useEffect } from "react";
import PetitonCreateUpdateForm, {
  PetitionFormData,
} from "../../components/Profile/PetitonCreateUpdateForm";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  editPetition,
  getPetitionDetailsAction,
} from "../../store/API/PetitionApis";
import { useNavigate, useParams } from "react-router-dom";

const PetitionEdit = () => {
  const { petitionDetails } = useAppSelector((state) => state.petition);

  const dispatch = useAppDispatch();
  let { id } = useParams();

  const router = useNavigate();
  const handleSubmit = (data: PetitionFormData) => {
    dispatch(editPetition({ postData: data, id, router }));
  };
  useEffect(() => {
    if (id) dispatch(getPetitionDetailsAction(parseInt(id, 10)));
  }, [id, dispatch]);
  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1 text-2xl font-bold">
              Edit Your
              <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text text-transparent ml-1">
                Petition
              </span>
            </h4>
          </div>
          <PetitonCreateUpdateForm
            onSubmit={handleSubmit}
            initialData={{
              title: petitionDetails?.title || "",
              description: petitionDetails?.description || "",
              target: String(petitionDetails?.target) || "",
              expire_datetime: petitionDetails?.expire_datetime
                ? new Date(petitionDetails.expire_datetime)
                : null,
              isPublished: petitionDetails?.status === "published",
              image: petitionDetails?.image || "",
              video: petitionDetails?.video || "",
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default PetitionEdit;
