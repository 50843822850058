import { Link } from "react-router-dom";
import { SingleAmbassadorType } from "../../models";
import { useAppSelector } from "../../store/hooks";

const AmbassadorsList = () => {
  const { ambassadorList } = useAppSelector((state) => state.auth);

  return (
    <section className="neta_list bg-[#fafbff] lg:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="w-full">
          {ambassadorList?.results?.map(
            (item: SingleAmbassadorType, index: number) => (
              <div key={item?.id} className="relative">
                {/* Content */}
                <div className="flex flex-col gap-2 md:gap-5 p-4 bg-white rounded-md shadow-theme_shadow">
                  <div className="flex sm:flex-row flex-col gap-2 md:gap-5">
                    {/* Profile Picture */}
                    <div className="mp_thumb h-[80px] w-[80px] flex-shrink-0">
                      <img
                        className="w-full h-full rounded-full border-2 border-green-500"
                        src={item?.user_details?.profile_pic}
                        alt="Profile"
                      />
                    </div>

                    {/* Ambassador Content */}
                    <div className="flex-1">
                      <Link to={`/public-profile?user=${item?.id}`}>
                        <h4 className="text-black md:text-xl text-lg font-medium hover:text-red-500">
                          {item?.first_name} {item?.last_name}
                        </h4>
                      </Link>
                      <p className="text-sm text-[#4a4a4a]">
                        <strong>Phone:</strong>{" "}
                        <a
                          className="text-green-500"
                          href={`tel:{item?.phone_number}`}
                        >
                          {" "}
                          {item?.phone_number}
                        </a>
                      </p>
                    </div>

                    {/* Facebook Link */}
                    {item?.user_details?.facebook_link && (
                      <div className="sm:mt-0 mt-2 h-16 w-16">
                        <Link
                          target="_blank"
                          to={`https://${item?.user_details?.facebook_link}`}
                        >
                          <img
                            src="https://img.icons8.com/?size=100&id=uLWV5A9vXIPu&format=png&color=000000"
                            alt="Facebook"
                          />
                        </Link>
                      </div>
                    )}
                  </div>

                  {/* Members Table */}
                  {item?.member?.length > 0 && (
                    <div>
                      <table className="hidden sm:table w-full text-left border border-gray-200 rounded-md overflow-hidden text-sm">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="px-4 py-2 font-semibold text-gray-600">
                              Name
                            </th>
                            <th className="px-4 py-2 font-semibold text-gray-600">
                              Seat
                            </th>
                            <th className="px-4 py-2 font-semibold text-gray-600">
                              Party
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.member?.map((mem, idx) => (
                            <tr
                              key={mem?.id}
                              className={`${
                                idx % 2 === 0 ? "bg-gray-50" : "bg-white"
                              } hover:bg-gray-100`}
                            >
                              <td className="px-4 py-2">
                                <Link
                                  to={`/neta/details/${mem?.id}`}
                                  className="text-green-500 font-medium hover:underline"
                                >
                                  {mem?.name}
                                </Link>
                              </td>
                              <td className="px-4 py-2">{mem?.seat}</td>
                              <td className="px-4 py-2">
                                {mem?.political_party}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Mobile-Friendly Cards */}
                      <div className="sm:hidden flex flex-col gap-3">
                        {item?.member?.map((mem) => (
                          <div
                            key={mem?.id}
                            className="p-3 border rounded-md bg-gray-50 shadow-sm"
                          >
                            <p className="text-sm">
                              <strong>Name:</strong>{" "}
                              <Link
                                to={`/neta/details/${mem?.id}`}
                                className="text-green-500 font-medium hover:underline"
                              >
                                {mem?.name}
                              </Link>
                            </p>
                            <p className="text-sm">
                              <strong>Seat:</strong> {mem?.seat}
                            </p>
                            <p className="text-sm">
                              <strong>Party:</strong> {mem?.political_party}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Separator */}
                {index < ambassadorList.results.length - 1 && (
                  <div className="items-center justify-center my-6 sm:flex hidden">
                    <div className="w-full border-t border-dashed border-gray-300"></div>
                    <div className="flex items-center justify-center w-12 bg-white rounded-full shadow-md mx-4">
                      <img
                        src="https://img.icons8.com/ios-filled/24/12E856/star--v1.png"
                        alt="Separator Icon"
                      />
                    </div>
                    <div className="w-full border-t border-dashed border-gray-300"></div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default AmbassadorsList;
