import { useEffect, useState } from "react";
import NetaListBanner from "../../../components/Neta/List/NetaListBanner";
import NetaProfile from "../../../components/Neta/Details/NetaProfile";
import NetaProfileDetailsSidebar from "../../../components/Neta/Details/NetaProfileDetailsSidebar";
import NetaProfileDetails from "../../../components/Neta/Details/NetaProfileDetails";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ambassadorSuggestion,
  getMemberDetails,
} from "../../../store/API/MemberApis";
import {
  memberQuestion,
  memberQuestionDetails,
  postQuestion,
} from "../../../store/API/QuestionAnswerApis";
import { ToastContainer, toast } from "react-toastify";
import AskQuestionModel from "../../../components/Neta/Details/AskQuestionModel";
import moment from "moment";
import ApplyAmbassadorModal from "../../../components/Neta/Details/ApplyAmbassadorModal";
import { getUserProfile } from "../../../store/API/UserApis";
import NetaSuggestions from "../../../components/Neta/Details/NetaSuggestions";
import auth from "../../../utils/auth";

const NetaDetails = () => {
  const { memberDetails, membersuggestions } = useAppSelector(
    (state) => state.member
  );
  const location = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const questionParams = searchParams.get("q");

  const [open, setOpen] = useState(false);
  const [openAmbassadorModal, setOpenAmbassadorModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const closeModel = () => {
    setOpen(false);
  };
  const closeAmbassadorModel = () => {
    setOpenAmbassadorModal(false);
  };

  const [toggleTab, setToggleTab] = useState<number>(
    location?.state?.paramsData ? location.state.paramsData : 1
  );

  useEffect(() => {
    if (location?.state?.paramsData && location?.state?.paramsType) {
      window.scrollTo({ top: 700, behavior: "smooth" });
    }
  }, [location?.state]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getMemberDetails(id));
      dispatch(memberQuestion(id));
    }
    if (questionParams) dispatch(memberQuestionDetails(questionParams));
  }, [dispatch, id, questionParams]);

  useEffect(() => {
    if (memberDetails?.seat && memberDetails?.upazila) {
      dispatch(
        ambassadorSuggestion({
          seat:
            typeof memberDetails?.seat === "object"
              ? memberDetails.seat.id
              : memberDetails?.seat,
          upazila:
            Array.isArray(memberDetails?.upazila) &&
            typeof memberDetails.upazila[0] === "object"
              ? memberDetails.upazila[0].id
              : memberDetails?.upazila?.[0],
          own_id: memberDetails?.id,
        })
      );
    }
  }, [memberDetails, dispatch]);

  useEffect(() => {
    if (auth.getToken()) {
      dispatch(getUserProfile());
    }
  }, [dispatch]);

  const handleSubmitQuestion = () => {
    dispatch(
      postQuestion({
        division_id: memberDetails?.division?.id,
        district_id: memberDetails?.district?.id,
        upazila_id:
          Array.isArray(memberDetails?.upazila) &&
          typeof memberDetails.upazila[0] === "object"
            ? memberDetails.upazila[0].id 
            : memberDetails?.upazila?.[0],
        seat_id:
          typeof memberDetails?.seat === "object"
            ? memberDetails.seat.id
            : memberDetails?.seat,
        member_id: memberDetails?.id,
        subject: subject,
        content: content,
        published_date: moment().format("MMMM Do YYYY, h:mm:ss a"),
        router: navigate,
      })
    );
    setOpen(false);
    setSubject("");
    setContent("");
    toast.success("Successfully submitted");
    setToggleTab(3);
    // if (memberDetails?.id) dispatch(memberQuestion(memberDetails?.id));
  };

  return (
    <>
      <ToastContainer />
      <NetaListBanner />
      <NetaProfile setOpen={setOpen} />
      <section className="profile_details lg:pb-10 pb-8">
        <div className="md:container container-fluid mx-auto text-start">
          <div className="grid grid-cols-12 gap-6">
            <NetaProfileDetailsSidebar
              toggleTab={toggleTab}
              setToggleTab={setToggleTab}
              memberId={id}
              setOpenAmbassadorModal={setOpenAmbassadorModal}
            />
            <NetaProfileDetails
              toggleTab={toggleTab}
              setToggleTab={setToggleTab}
              paramsId={questionParams}
            />
          </div>
        </div>
      </section>
      <NetaSuggestions members={membersuggestions} />
      <AskQuestionModel
        open={open}
        closeModal={closeModel}
        subject={subject}
        setSubject={setSubject}
        content={content}
        setContent={setContent}
        handleSubmitQuestion={handleSubmitQuestion}
      />
      <ApplyAmbassadorModal
        closeAmbassadorModel={closeAmbassadorModel}
        openAmbassadorModal={openAmbassadorModal}
      />
    </>
  );
};

export default NetaDetails;
