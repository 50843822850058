import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { getUserProfile } from "../../store/API/UserApis";
import { userAmbassadorQuestionListAction, userQuestionListAction } from "../../store/API/QuestionAnswerApis";
import ProfileQuestionList from "../../components/Profile/ProfileQuestionList";
import ProfileDetails from "../../components/Profile/ProfileDetails";
import MyPetition from "../../components/Profile/MyPetition";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Profile = () => {
  const [toggleTab, setToggleTab] = useState(1);
  const dispatch = useAppDispatch();
  const limit = 4;
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const tabValue = searchParams.get("tab");
    if (tabValue !== null) {
      setToggleTab(parseInt(tabValue, 10));
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(userQuestionListAction({ page: 1, page_size: limit }));
    dispatch(userAmbassadorQuestionListAction({ page: 1, page_size: limit }));
  }, [dispatch]);
  return (
    <section className="amb_profile bg-[#fafbff] lg:py-10 py-8">
      <ToastContainer />
      <div className="container-fluid mx-auto">
        <div className="w-full mx-auto">
          <ul className="flex gap-3 whitespace-nowrap overflow-x-auto">
            <li>
              <button
                type="button"
                onClick={() => setToggleTab(1)}
                className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal ${
                  toggleTab === 1
                    ? "bg-red-500 hover:bg-red-500"
                    : "hover:bg-red-500"
                }`}
              >
                Profile
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => setToggleTab(2)}
                className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal ${
                  toggleTab === 2
                    ? "bg-red-500 hover:bg-red-500"
                    : "hover:bg-red-500"
                }`}
              >
                Questions
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => setToggleTab(3)}
                className={`bg-green-500 p-2 w-full rounded-md text-white md:text-base text-sm font-normal ${
                  toggleTab === 3
                    ? "bg-red-500 hover:bg-red-500"
                    : "hover:bg-red-500"
                }`}
              >
                My Petition
              </button>
            </li>
          </ul>
          <div className="p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300 mt-7">
            {toggleTab === 1 && <ProfileDetails />}

            {toggleTab === 2 && <ProfileQuestionList />}
            {toggleTab === 3 && <MyPetition />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
