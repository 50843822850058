import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../settings/config";
import useAxios from "../../utils/useAxios";
import { NavigateFunction } from "react-router-dom";

export const topQuestion = createAsyncThunk(
  "TopQuestion/top",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/top_question`
        );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const recentAnswer = createAsyncThunk(
  "RecentAnswer/top",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/recent_answer`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const memberQuestion = createAsyncThunk(
  "member/question",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/member?q=${id}`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const memberQuestionDetails = createAsyncThunk(
  "member/question-details",
  async (id: string | number, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/question_answer?q=${id}`
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const postAnswer = createAsyncThunk(
  "post/answer",
  async (
    data: {
      question?: number;
      member?: string;
      content: string;
      published_date: string;
      router: NavigateFunction;
    },
    thunkAPI
  ) => {
    try {
      let api = useAxios();
      const {router, ...postData} = data
      const response = await api.post(
        `/v1/questionnaire/answer-create`,
        postData
      );
      // router(`/neta/details/1?q=${data.question}`);
      if (data.question)
        thunkAPI.dispatch(memberQuestionDetails(data.question)); // 
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const postQuestion = createAsyncThunk(
  "post/question",
  async (
    data: {
      division_id?: number;
      district_id?: number;
      upazila_id?: any;
      seat_id?: number;
      member_id?: number;
      subject?: string;
      content: string;
      published_date: string;
      router: NavigateFunction;
    },
    thunkAPI
  ) => {
    try {
      let api = useAxios();
      const { router, ...postData } = data;
      const response = await api.post(
        `/v1/questionnaire/question-create`,
        postData
      );
      if (data.member_id) {
        thunkAPI.dispatch(memberQuestion(data.member_id));
      }
        
      //   router(`/neta/details/${data.member_id}?type=list`, {state:{ paramsData: 3, paramsType: "Question" }});
      return response.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error so it can be handled by the rejected action
    }
  }
);

export const questionListAction = createAsyncThunk(
  "question/list",
  async (
    paramsData: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/question-list`, {
          params: paramsData
        }
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const answerListAction = createAsyncThunk(
  "answer/list",
  async (
    paramsData: any,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/v1/questionnaire/answer-list`, {
          params: paramsData
        }
      );   
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const userQuestionListAction = createAsyncThunk(
  "user-question/list",
  async (paramsData: any, { rejectWithValue }) => {
    let api = useAxios();
    try {
      const { data } = await api.get(
        `${API_URL}/v1/questionnaire/user-question-list`,
        {
          params: paramsData
        }
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const userAmbassadorQuestionListAction = createAsyncThunk(
  "user-ambassador-question/list",
  async (paramsData: any, { rejectWithValue }) => {
    let api = useAxios();
    try {
      const { data } = await api.get(
        `${API_URL}/v1/questionnaire/member-question-list`,
        {
          params: paramsData,
        }
      );
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
