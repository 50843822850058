import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import moment from "moment";
import { Signature } from "../../models";
import Pagination from "../common/Pagination";
import { useParams } from "react-router-dom";
import { getUserSignatureAction } from "../../store/API/PetitionApis";

const SignaturesAllSignatures = () => {
  const { userSignature } = useAppSelector((state) => state.petition);
  const limit = 10;
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const handlePageClick = (data: any) => {
    dispatch(
      getUserSignatureAction({
        page: data.selected + 1,
        page_size: limit,
        petition: id,
      })
    );
    window.scrollTo({
      top: 180,
      behavior: "smooth",
    });
  };
  return (
    <div className="bg-white p-6 rounded-md shadow-theme_shadow mt-4">
      <h4 className="text-black text-opacity-70 xl:text-3xl md:text-2xl text-xl font-semibold pb-4">
        All Signatures
      </h4>
      <div className="relative overflow-x-auto mt-4">
        <table className="w-full text-sm text-left 0">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 bg-gray-50">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Opinion
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50">
                Constituency
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {userSignature?.signatures?.results &&
              userSignature?.signatures?.results?.length > 0 &&
              userSignature?.signatures?.results?.map((item: Signature) => (
                <tr className="border-b border-gray-200" key={item?.id}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-green-500 whitespace-nowrap bg-gray-50"
                  >
                    {item?.user}
                  </th>
                  <td className="px-6 py-4 min-w-[250px]">{item?.opinion}</td>
                  <td className="px-6 py-4 bg-gray-50">{item?.user_seat}</td>
                  <td className="px-6 py-4">
                    {item?.signed_at
                      ? moment(item.signed_at).format("YYYY-MM-DD")
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
          <Pagination
            pageCount={
              userSignature?.signatures?.count &&
              Math.ceil(userSignature?.signatures?.count / limit)
            }
            handlePageClick={handlePageClick}
            pageRange={5}
          />
        </table>
      </div>
    </div>
  );
};

export default SignaturesAllSignatures;
