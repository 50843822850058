import { Link } from "react-router-dom";
import { MemberType } from "../../../models";
import { useAppSelector } from "../../../store/hooks";

interface PageProps {
  limit: number;
  total?: number;
  selectedPage?: number;
}

const ListContent: React.FC<PageProps> = ({
  limit,
  total,
  selectedPage = 0,
}) => {
  const { memberList } = useAppSelector((state) => state.member);
  return (
    <div className="md:w-[calc(100%_-_220px)] w-full">
      <div x-show="openTab === 1">
        <div className="bg-white py-6 px-3 rounded-md shadow-theme_shadow mb-5">
          <p className="text-[#4a4a4a] text-base font-normal">
            Showing MP {(selectedPage - 1) * limit + 1} to{" "}
            {total ? Math.min(selectedPage * limit, total) : 0} - Total: {total}
          </p>
        </div>
        <div className="bg-white rounded-md shadow-theme_shadow overflow-x-auto">
          {/* Table for larger screens */}
          <table className="hidden md:table min-w-[800px] w-full border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="p-4 border border-gray-300">Profile</th>
                <th className="p-4 border border-gray-300">Name</th>
                <th className="p-4 border border-gray-300">Designation</th>
                <th className="p-4 border border-gray-300">Seat</th>
                <th className="p-4 border border-gray-300">Political Party</th>
              </tr>
            </thead>
            <tbody>
              {memberList?.results?.members?.map((item: MemberType) => (
                <tr key={item?.id} className="hover:bg-gray-50">
                  <td className="p-4 border border-gray-300">
                    <div className="h-[80px] w-[80px] mx-auto">
                      <img
                        className="w-full h-full rounded-full border-2 border-green-500"
                        src={item?.profile_pic}
                        alt={item?.name}
                      />
                    </div>
                  </td>
                  <td className="p-4 border border-gray-300">
                    <Link to={`/neta/details/${item?.id}`}>
                      <h4 className="text-black text-base font-medium hover:text-red-500">
                        {item?.name}
                      </h4>
                    </Link>
                  </td>
                  <td className="p-4 border border-gray-300">
                    <span className="text-green-500 text-base font-normal">
                      {item?.designation}
                    </span>
                  </td>
                  <td className="p-4 border border-gray-300">
                    <span className="text-[#4a4a4a] text-base font-normal">
                      {typeof item?.seat === "object" ? item?.seat?.name : ""}
                    </span>
                  </td>
                  <td className="p-4 border border-gray-300">
                    <span className="text-[#4a4a4a] text-base font-normal">
                      {typeof item?.political_party === "object"
                        ? item?.political_party?.name
                        : ""}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Card view for smaller screens */}
          <div className="md:hidden flex flex-col gap-4">
            {memberList?.results?.members?.map((item: MemberType) => (
              <div
                key={item?.id}
                className="flex flex-col gap-2 p-4 border border-gray-300 rounded-md"
              >
                <div className="flex items-center gap-4">
                  <div className="h-[80px] w-[80px]">
                    <img
                      className="w-full h-full rounded-full border-2 border-green-500"
                      src={item?.profile_pic}
                      alt={item?.name}
                    />
                  </div>
                  <Link to={`/neta/details/${item?.id}`}>
                    <h4 className="text-black text-lg font-medium hover:text-red-500">
                      {item?.name}
                    </h4>
                  </Link>
                </div>
                <div className="text-sm text-left">
                  {item?.designation && (
                    <div>
                      <strong>Designation:</strong>{" "}
                      <span className="text-green-500">
                        {item?.designation}
                      </span>
                    </div>
                  )}
                  <div>
                    <strong>Location:</strong>{" "}
                    <span className="text-[#4a4a4a]">
                      {typeof item?.seat === "object" ? item.seat?.name : ""}
                    </span>
                  </div>
                  <div>
                    <strong>Political Party:</strong>{" "}
                    <span className="text-[#4a4a4a]">
                      {typeof item?.political_party === "object"
                        ? item?.political_party?.name
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListContent;
