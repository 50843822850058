import React, { useEffect, useState } from 'react'
import AmbassadorsList from '../../components/Ambassadors/AmbassadorsList';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAmbassadorList } from '../../store/API/UserApis';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchNeta from '../../components/Home/SearchNeta';
import Pagination from '../../components/common/Pagination';

const Ambassadors = () => {
  const { ambassadorList } = useAppSelector(state => state.auth)
  const [remountComponent, setRemountComponent] = useState(0);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const limit = 4;
  useEffect(() => {
    if (searchParams.get("seat")) {
      dispatch(
        getAmbassadorList({
          page: 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getAmbassadorList({
          page: 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getAmbassadorList({
          page: 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getAmbassadorList({
          page: 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getAmbassadorList({
          page: 1,
          page_size: limit,
        })
      );
    }
  }, [searchParams, dispatch]);
  const handlePageClick = (data: any) => {
    if (searchParams.get("seat")) {
      dispatch(
        getAmbassadorList({
          page: data.selected + 1,
          page_size: limit,
          seat: searchParams.get("seat"),
        })
      );
    } else if (searchParams.get("upazila")) {
      dispatch(
        getAmbassadorList({
          page: data.selected + 1,
          page_size: limit,
          upazila: searchParams.get("upazila"),
        })
      );
    } else if (searchParams.get("district")) {
      dispatch(
        getAmbassadorList({
          page: data.selected + 1,
          page_size: limit,
          district: searchParams.get("district"),
        })
      );
    } else if (searchParams.get("division")) {
      dispatch(
        getAmbassadorList({
          page: data.selected + 1,
          page_size: limit,
          division: searchParams.get("division"),
        })
      );
    } else {
      dispatch(
        getAmbassadorList({
          page: data.selected + 1,
          page_size: limit,
        })
      );
    }
    window.scrollTo({
      top: 180,
      behavior: "smooth",
    });
  };
  const handleSearchSubmit = (data: any) => {
    if (data?.seat) {
      navigate(`/ambassadors/list?seat=${data?.seat?.value}`);
    } else if (data?.upazila) {
      navigate(`/ambassadors/list?upazila=${data?.upazila?.value}`);
    } else if (data?.district) {
      navigate(`/ambassadors/list?district=${data?.district?.value}`);
    } else if (data?.division) {
      navigate(`/ambassadors/list?division=${data?.division?.value}`);
    }
    setRemountComponent(Math.random());
  };
  
  return (
    <section className="three_section bg-[#fafbff] lg:py-10 py-8 text-start">
      <div className="container-fluid mx-auto">
        <h3 className="text-green-500 xl:text-2xl md:text-xl text-lg text-center font-medium">
          <span className="text-red-500 inline-block relative z-[1] p-2 after:absolute after:block after:left-0 after:top-0 after:bg-white after:border after:shadow-theme_shadow after:w-full after:h-full after:skew-x-[20deg] after:-z-10">
            Ambassadors
          </span>
          Update
          <span>(Total: {ambassadorList?.count})</span>
        </h3>
        <SearchNeta
          handleSearchSubmit={handleSearchSubmit}
          title="Ambassadors"
        />
        <AmbassadorsList />
        <div className="mt-8 flex justify-end" key={remountComponent}>
          <Pagination
            pageCount={
              ambassadorList?.count && Math.ceil(ambassadorList?.count / limit)
            }
            handlePageClick={handlePageClick}
            pageRange={5}
          />
        </div>
      </div>
    </section>
  );
}

export default Ambassadors