import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../settings/config";
import useAxios from "../../utils/useAxios";

export const createPetition = createAsyncThunk(
  "petition/create",
  async (data: any, thunkAPI) => {
    const { postData, router } = data;
    try {
      let api = useAxios();
      const response = await api.post(
        `${API_URL}/v1/petition/petitions/`,
        postData
      );
      router(`/profile?tab=3`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const editPetition = createAsyncThunk(
  "petition/edit",
  async (data: any, thunkAPI) => {
    const { router, id, postData } = data;
    try {
      let api = useAxios();
      const response = await api.patch(
        `${API_URL}/v1/petition/petitions/${id}/`,
        postData
      );
      router(`/profile?tab=3`);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const myPetitionListAction = createAsyncThunk(
  "petition/owner",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data } = await api.get(`${API_URL}/v1/petition/owner`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPetitionDetailsAction = createAsyncThunk(
  "petition/details",
  async (id: number, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data } = await api.get(`${API_URL}/v1/petition/petitions/${id}`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const petitionDeleteAction = createAsyncThunk(
  "petition/details",
  async (data: any, { rejectWithValue, dispatch }) => {
    const {  id, ...paramsData } = data;
    try {
      let api = useAxios();
      const { data } = await api.delete(`${API_URL}/v1/petition/petitions/${id}`);
      dispatch(myPetitionListAction(paramsData));
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const publicPetitionListAction = createAsyncThunk(
  "petition/public",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data } = await api.get(`${API_URL}/v1/petition/petitions`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const petitionSignature = createAsyncThunk(
  "user/petition/signature",
  async (data: any, thunkAPI) => {
    try {
      let api = useAxios();
      const response = await api.post(
        `${API_URL}/v1/petition/signatures/`,
        data,
      );
      thunkAPI.dispatch(getUserSignatureAction({ petition: data.petition }));
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserSignatureAction = createAsyncThunk(
  "petition/user/signature",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data } = await api.get(`${API_URL}/v1/petition/user`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);


