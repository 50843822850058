import parse from "html-react-parser";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const PetitionDetailsContentSummery = () => {
  const { petitionDetails } = useAppSelector((state) => state.petition);
  return (
    <div className="lg:col-span-8 col-span-12">
      <div className="bg-white p-6 rounded-md shadow-theme_shadow">
        <div className="flex flex-wrap items-center gap-2 mb-5">
          <p className="text-green-500 text-base font-normal">
            Petition Created By:
            <span className="font-medium">{petitionDetails?.created_by}</span>,
          </p>
          <span className="text-[#4a4a4a] text-opacity-70">
            {petitionDetails?.published_datetime
              ? moment(petitionDetails.published_datetime).format(
                  "MMM D, YYYY h:mm A"
                )
              : "N/A"}
          </span>
        </div>
        {petitionDetails?.image && (
          <div className="post_thumb mb-4">
            <Link to={"/"} className="relative block w-full h-full">
              <img
                className="rounded-md object-contain w-full h-auto"
                src={petitionDetails?.image}
                alt={petitionDetails?.title}
              />
            </Link>
          </div>
        )}
        {petitionDetails?.video && (
          <div className="post_thumb mb-4">
            <div className="relative block w-full h-full">
              <ReactPlayer
                url={petitionDetails?.video || ""}
                controls={true}
                playing={true}
                width="100%"
                height={"100%"}
                className="aspect-video height-auto"
                config={{ youtube: { playerVars: { autoplay: 1 } } }}
              />
            </div>
          </div>
        )}

        {petitionDetails?.description && parse(petitionDetails?.description)}
        <div className="pt-8">
          <h4 className="text-black text-opacity-60 xl:text-3xl md:text-2xl text-xl font-semibold">
            Petition Statistics
          </h4>
          <p className="text-black text-opacity-70 text-base font-normal py-2">
            Target: {petitionDetails?.target}
          </p>
          <p className="text-black text-opacity-70 text-base font-normal">
            Total:{" "}
            {petitionDetails?.signatures_count
              ? petitionDetails?.signatures_count
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PetitionDetailsContentSummery;
