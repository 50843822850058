import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUser } from "../../store/API/UserApis";
import { logout } from "../../store/auth.store";
import auth from "../../utils/auth";
import LogoImage from "../../images/logo/logoImg.jpg";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setSidebarOpen(false);
    setDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    if (auth.getToken()) {
      dispatch(getUser());
    }
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const getActiveClass = (path: string) => {
    // Check for exact match or special condition for "Neta"
    if (
      location.pathname === path ||
      (path === "/news/list" && location.pathname.startsWith("/news/")) ||
      (path === "/neta/list" && location.pathname.startsWith("/neta/"))
    ) {
      return "text-green-500 font-semibold";
    }
    return "hover:text-green-400";
  };

  return (
    <header className="header_area bg-white py-4 sticky top-0 z-[999] shadow-theme_shadow">
      <div className="container-fluid mx-auto">
        <div className="flex items-center justify-between gap-5">
          <div className="brand_logo">
            <Link
              to={"/"}
              className="flex items-center gap-1 text-2xl font-semibold font-poppins group"
            >
              <img
                src={LogoImage}
                alt=""
                className="h-14 min-w-36 overflow-hidden"
              />
            </Link>
          </div>
          <div
            className={`main_menu md:bg-transparent bg-o md:flex items-center md:static fixed top-0 left-0 max-md:w-screen max-md:h-screen z-[99] overflow-auto md:overflow-visible ${
              sidebarOpen ? "" : "hidden"
            }`}
          >
            <ul className="flex md:flex-row flex-col items-center md:gap-5 gap-10 md:h-auto h-screen max-md:justify-center md:text-black text-white text-opacity-60 lg:text-lg md:text-base text-xl md:font-normal font-medium font-poppins">
              <li>
                <Link
                  to="/neta/list"
                  className={`px-2 transition-all ${getActiveClass(
                    "/neta/list"
                  )}`}
                >
                  Neta
                </Link>
              </li>
              <li>
                <Link
                  to="/questions/list"
                  className={`px-2 transition-all ${getActiveClass(
                    "/questions/list"
                  )}`}
                >
                  Question
                </Link>
              </li>
              <li>
                <Link
                  to="/answers/list"
                  className={`px-2 transition-all ${getActiveClass(
                    "/answers/list"
                  )}`}
                >
                  Answer
                </Link>
              </li>
              <li>
                <Link
                  to="/news/list"
                  className={`px-2 transition-all ${getActiveClass(
                    "/news/list"
                  )}`}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  to="/petition"
                  className={`px-2 transition-all ${getActiveClass(
                    "/petition"
                  )}`}
                >
                  Petitions
                </Link>
              </li>
              <li>
                <Link
                  to="/ambassadors/list"
                  className={`px-2 transition-all ${getActiveClass(
                    "/ambassadors/list"
                  )}`}
                >
                  Ambassadors
                </Link>
              </li>
              {user?.id ? (
                <li className="relative min-w-[140px]">
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="flex items-center gap-2 px-4 py-2 text-black bg-white border rounded-md hover:bg-gray-100"
                  >
                    <span>{user?.user?.first_name || "User"}</span>
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      className={`w-4 h-4 transition-transform ${
                        dropdownOpen ? "rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 9l6 6 6-6"
                      ></path>
                    </svg>
                  </button>
                  {dropdownOpen && (
                    <div className="absolute left-0 mt-2 bg-white border rounded-md shadow-lg text-left">
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        Profile
                      </Link>
                      <Link
                        to="/profile?tab=3"
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        Petition
                      </Link>
                      {user?.neta?.id ? (
                        <Link
                          to={`/neta/details/${user?.neta?.member}`}
                          className="block px-4 py-2 text-black hover:bg-gray-100"
                        >
                          Amar Neta
                        </Link>
                      ) : (
                        <Link
                          to={`/neta/select`}
                          className="block px-4 py-2 text-black hover:bg-gray-100"
                        >
                          Amar Neta
                        </Link>
                      )}
                      <button
                        onClick={() => {
                          handleLogout();
                          setDropdownOpen(false);
                        }}
                        className="block w-full px-4 py-2 text-black hover:bg-gray-100 text-left"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </li>
              ) : (
                <Link
                  to="/login"
                  className="bg-black rounded-3xl py-2 px-6 text-white md:text-lg font-medium font-poppins"
                >
                  Login
                </Link>
              )}
            </ul>
          </div>
          <div
            className={`mobile_menu md:hidden relative z-[999] ${
              sidebarOpen && "text-white"
            }`}
          >
            <button
              type="button"
              className="bg-transparent flex flex-col gap-1"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                className="h-5 w-6"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
