import React from "react";
import PetitonCreateUpdateForm from "../../components/Profile/PetitonCreateUpdateForm";
import { useAppDispatch } from "../../store/hooks";
import { createPetition } from "../../store/API/PetitionApis";
import { useNavigate } from "react-router-dom";

const PetitionCreate = () => {
    const dispatch = useAppDispatch()
    const router = useNavigate();
  const handleSubmit = (data: any) => {
    dispatch(createPetition({ postData: data, router }));
  };
  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1 text-2xl font-bold">
              Create Your
              <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text text-transparent ml-1">
                Petition
              </span>
            </h4>
          </div>
          <PetitonCreateUpdateForm onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default PetitionCreate;
